import React from 'react';

const useOptionsPage = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const validatePage = (loading: boolean, numberPage: number) => {
    return loading ? 0 : numberPage;
  };

  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    validatePage,
  };
};

export default useOptionsPage;
