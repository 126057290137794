export const amazonRedirectSPAPI = 'spapi_oauth_code';
export const amazonRedirectState = 'state';
export const amazonRedirectSellingPartnerId = 'selling_partner_id';

interface IStatus {
  pending: string;
  'partial received': string;
  received: string;
  shipped: string;
  canceled: string;
  cancelled: string;
  done: string;
  completed: string;
  'over received': string;
}

export const status: IStatus = {
  pending: 'pending',
  'partial received': 'partial received',
  received: 'received',
  shipped: 'shipped',
  canceled: 'canceled',
  cancelled: 'cancelled',
  done: 'done',
  completed: 'completed',
  'over received': 'over received',
};

export const textTooltipOrderStatus = {
  pending: 'Status the order Pending',
  shipped: 'Status the order shipped',
  cancelled: 'Status the order canceled',
  canceled: 'Status the order canceled',
  done: 'Status the order done',
  completed: 'Order fully shipped by Amazon',
  received: 'Status the order received',
  partialReceived: '',
};

export const textTooltipInternalStatus = {
  pending: 'Not all products ordered from Amazon have been shipped yet',
  shipped: 'All quantities ordered from Amazon were shipped',
  cancelled: 'Delivery request from Amazon has been canceled',
  canceled: 'Delivery request from Amazon has been canceled',
  partialReceived:
    'A partial quantity of the expected items has been processed',
  done: 'Confirmed receipt of items',
  completed: 'Confirmed receipt of items',
  received: 'All expected items have been processed',
  overReceived: 'A higher than expected quantity of products was received.',
};

export const dataTableFields = [
  {
    field: 'orderId',
    headerName: 'Order ID',
    sort: false,
  },
  {
    field: 'mwsSellerId',
    headerName: 'Marketplace Seller ID',
    sort: false,
  },
  {
    field: 'company',
    headerName: 'Company',
    sort: false,
  },
  {
    field: 'customerFirstName',
    headerName: 'Customer First Name',
    sort: false,
  },
  {
    field: 'customerLastName',
    headerName: 'Customer Last Name',
    sort: false,
  },
  {
    field: 'customerEmail',
    headerName: 'Customer Email',
    sort: false,
  },
  {
    field: 'orderStatus',
    withChip: true,
    headerName: 'Order Status',
    sort: false,
    textTooltip: textTooltipOrderStatus,
  },
  {
    field: 'internalStatus',
    withChip: true,
    headerName: 'Internal Status',
    sort: false,
    textTooltip: textTooltipInternalStatus,
  },

  {
    field: '',
    headerName: 'Detail',
    sort: false,
  },
];

export const dataHeaders = [
  {
    field: 'items',
    headerName: 'Items',
    sort: false,
  },
  ...dataTableFields,
];

export const dataFieldsCollapseRemoval = [
  {
    field: 'asin',
    headerName: 'ASIN',
  },
  {
    field: 'carrier',
    headerName: 'Carrier',
  },
  {
    field: 'fnsku',
    headerName: 'FNSKU',
  },
  {
    field: 'itemName',
    headerName: 'Item Name',
  },
  {
    field: 'sku',
    headerName: 'SKU',
  },
  {
    field: 'trackingNumber',
    headerName: 'Tracking Number',
    isTrackingCollapse: true,
  },
  {
    field: 'inprocessQty',
    headerName: 'In-Process Qty',
  },
  {
    field: 'shippedQty',
    headerName: 'Shipped Qty',
  },
  {
    field: 'receivedQty',
    headerName: 'Received Qty',
  },
  {
    field: 'rejectedQty',
    headerName: 'Rejected Qty',
  },
];

export const inputsFilters = [
  { id: 'orderId', name: 'orderId', label: 'Order ID' },
  { id: 'mwsSellerId', name: 'mwsSellerId', label: 'MWS SellerId' },
  { id: 'company', name: 'company', label: 'Company' },
  {
    id: 'customerFirstName',
    name: 'customerFirstName',
    label: 'Customer FirstName',
  },
  {
    id: 'customerLastName',
    name: 'customerLastName',
    label: 'Customer LastName',
  },
  { id: 'customerEmail', name: 'customerEmail', label: 'Customer Email' },
  {
    id: 'trackingNumber',
    name: 'trackingNumber',
    label: 'Tracking Number',
    isMultipleValueInput: true,
  },
];

export const selectFilters = [
  {
    name: 'orderStatus',
    menuItem: [
      {
        value: '',
        labelName: 'Order Status',
        disabled: true,
      },
      {
        value: 'pending',
        labelName: 'Pending',
      },
      {
        value: 'shipped',
        labelName: 'Shipped',
      },
      {
        value: 'canceled',
        labelName: 'Canceled',
      },
      {
        value: 'done',
        labelName: 'Done',
      },
      {
        value: 'received',
        labelName: 'Received',
      },
    ],
  },
  {
    name: 'internalStatus',
    menuItem: [
      {
        value: '',
        labelName: 'Internal Status',
        disabled: true,
      },
      {
        value: 'Pending',
        labelName: 'Pending',
      },
      {
        value: 'partial received',
        labelName: 'Partial Received',
      },
      {
        value: 'Received',
        labelName: 'Received',
      },
    ],
  },
];

export const dataCollapseRemoval = 'items';
export const titleCollapseRemoval = 'Items - Order Id';
export const propertyTitleRemoval = 'orderId';

export const dataTableRowsPerPage = 10;
