import { ApolloError, useQuery } from '@apollo/client';
import { GET_ORDERS_OPERATORS } from 'api/endPoints/removalOrders/queries';
import { IRemovalOrdersExternal } from 'interfaces/RemovalOrders';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: IRemovalOrdersExternal | undefined;
  refetch: () => void;
}

export interface IOrdersFilters {
  orderId?: string;
  orderStatus?:
    | 'pending'
    | 'shipped'
    | 'canceled'
    | 'done'
    | 'received'
    | unknown;
  internalStatus?: 'pending' | 'partial received' | 'received' | unknown;
  mwsSellerId?: string;
  company?: string;
  customerEmail?: string;
  customerFirstName?: string;
  customerLastName?: string;
}

export interface IRemovalOrdersVars {
  reqPage: number;
  reqLimit: number;
  filters: IOrdersFilters;
}

export const useGetRemovalOrdersGraphql = ({
  reqPage,
  reqLimit,
  filters,
}: IRemovalOrdersVars): IEndpointResponse => {
  const { loading, error, data, refetch } = useQuery<
    IRemovalOrdersExternal,
    IRemovalOrdersVars
  >(GET_ORDERS_OPERATORS, {
    variables: {
      reqLimit,
      reqPage,
      filters,
    },
  });

  return {
    loading,
    error,
    data,
    refetch,
  };
};
