import { gql } from '@apollo/client';

export const GET_ORDERS_OPERATORS = gql`
  query OrdersOperators(
    $reqLimit: Int!
    $reqPage: Int!
    $filters: OrdersFilter!
  ) {
    ordersOperators(reqLimit: $reqLimit, reqPage: $reqPage, filters: $filters) {
      data {
        _id
        company
        customerEmail
        customerFirstName
        customerLastName
        orderId
        mwsSellerId
        orderStatus
        internalStatus
        items {
          _id
          asin
          carrier
          fnsku
          inprocessQty
          itemName
          trackingNumber
          sku
          shippedQty
          receivedQty
          rejectedQty
        }
      }
      page
      status
      totalDocs
      totalPages
    }
  }
`;

export const GET_ORDER_DETAIL = gql`
  query OrderDetail($_id: String!) {
    ordersOperatorsDetail(_id: $_id) {
      status
      data {
        received {
          products {
            receivingStatus
            name
            asin
            _id
            condition
            fnsku
            internalSku
            productPhotos
          }
          _id
          boxPhotos
          tracking
          boxStatus
        }
        _id
        orderId
        orderStatus
        internalStatus
        createdAt
        itemsQty
        customerEmail
        customerFirstName
        customerLastName
        mwsSellerId
        company
        items {
          _id
          asin
          fnsku
          inprocessQty
          itemName
          receivedQty
          rejectedQty
          shippedQty
          sku
        }
      }
    }
  }
`;
