import { ApolloClient, InMemoryCache } from '@apollo/client';
import React from 'react';

const useClientGrapql = () => {
  const [stateToken, setStateToken] = React.useState(null);

  const userData = localStorage.getItem('userData');

  React.useEffect(() => {
    if (userData) {
      const converted = JSON.parse(userData);

      setStateToken(converted.token);
    }
  }, [userData]);

  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    cache: new InMemoryCache(),
    headers: {
      'x-api-key': `${process.env.REACT_APP_GRAPHQL_API_KEY}`,
      Authorization: `Bearer ${stateToken !== null && stateToken}`,
    },
  });

  return client;
};

export default useClientGrapql;
