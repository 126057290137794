import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
/*import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';*/
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InputIcon from '@material-ui/icons/Input';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ContactsIcon from '@material-ui/icons/Contacts';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SendIcon from '@material-ui/icons/Send';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import BallotIcon from '@material-ui/icons/Ballot';

import SidebarNav from './SidebarNav';
import { sidebarViewStatus } from '@base/common/context/SidebarViewStatusContext';
import { usePermission } from '@base/common/hooks/permission-hook';
import { useHttpClient } from '@base/common/hooks/http-hook';
import { AuthContext } from '@base/common/context/auth-context';
import useStatesGlobalsStore from 'hooks/useStatesGlobalsStore';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing(2.4),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  tenant: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const { openSidebarDynamic, setOpenSidebarDynamic, controlSidebar } =
    useContext(sidebarViewStatus);

  const { authInfo } = useStatesGlobalsStore();

  const pagesAdmin = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      openBubble: false,
      icon: <DashboardIcon />,
    },
    {
      title: 'Account',
      href: '/account',
      openBubble: true,
      moduleBubble: 'account',
      icon: <AccountBoxIcon />,
    },
    {
      id: 'removalOrdersLink',
      title: 'Removal orders',
      href: '/removal-orders',
      openBubble: true,
      moduleBubble: 'removalorders',
      icon: <FileCopyIcon />,
    },
    {
      title: 'Customers',
      href: '/customers',
      openBubble: false,
      icon: <ContactsIcon />,
    },
    {
      title: 'Products',
      href: '/Products',
      openBubble: false,
      icon: <CardTravelIcon />,
    },
    {
      title: 'Receive box',
      href: '/receive-box',
      openBubble: true,
      moduleBubble: 'receivebox',
      icon: <AccountBalanceWalletIcon />,
    },
    {
      title: 'Active Listings Status',
      href: '/active-listings',
      openBubble: false,
      icon: <BallotIcon />,
    },
    {
      title: 'Sales',
      href: '/sales',
      openBubble: false,
      icon: <LocalMallIcon />,
    },
    {
      title: 'Create FBA Shipment',
      href: '/Create FBA Shipment',
      openBubble: false,
      icon: <SendIcon />,
    },
    {
      title: 'Settings',
      href: '/settings',
      openBubble: false,
      icon: <SettingsIcon />,
    },
    {
      title: 'Logout',
      href: '/sign-in',
      logout: true,
      icon: <InputIcon />,
    },
  ];

  const pagesUser = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />,
    },
    {
      title: 'Receive box',
      href: '/receive-box',
      icon: <AccountBalanceWalletIcon />,
    },
    {
      title: 'Active Listings Status',
      href: '/active-listings',
      icon: <BallotIcon />,
    },
    {
      title: 'Logout',
      href: '/sign-in',
      logout: true,
      icon: <InputIcon />,
    },
  ];

  return (
    <Drawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      {
        <div
          {...rest}
          className={clsx(
            `${classes.root} ${className} ${openSidebarDynamic && 'w-52'}`,
          )}>
          <div className='flex justify-between p-2 align-middle'>
            {openSidebarDynamic && (
              <Typography align='left' variant='h5' className={classes.tenant}>
                {props.tenantname}
              </Typography>
            )}
            <MenuIcon
              onClick={controlSidebar}
              className={`cursor-pointer mt-3 ${!openSidebarDynamic && 'ml-1'}`}
            />
          </div>
          <Divider className={classes.divider} />
          <SidebarNav
            className={classes.nav}
            pages={authInfo?.accountOwner ? pagesAdmin : pagesUser}
            stateSidebar={openSidebarDynamic}
          />
        </div>
      }
    </Drawer>
  );
};

export default Sidebar;
