import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import useClientGrapql from 'config/graphql/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ApolloProvider } from '@apollo/client';

import './index.css';

const Main = () => {
  const client = useClientGrapql();

  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();

reportWebVitals();
