import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query MyQuery($reqLimit: Int!, $reqPage: Int!, $filters: ProductsFilter!) {
    productsGetList(reqLimit: $reqLimit, reqPage: $reqPage, filters: $filters) {
      totalDocs
      totalPages
      page
      status
      data {
        _id
        asin
        newAsin
        internalId
        company
        customerFnsku
        customerSku
        internalFnsku
        internalSku
        itemName
      }
    }
  }
`;

export const GET_NEW_ASIN_LOGS = gql`
  query MyQuery($idProduct: String!, $newAsin: String!) {
    ordersGetAsinHistoryLog(idProduct: $idProduct, newAsin: $newAsin) {
      data {
        _id
        asin
        createdAt
        operatorId
        updatedAt
        operatorName
      }
      status
    }
  }
`;
