import { Typography } from '@material-ui/core';
import React from 'react';
import ProductsList from './containers/list';
import useStatesGlobalsStore from 'hooks/useStatesGlobalsStore';
import FetchApiGet from 'hooks/useValidationAccess';
import { useAuth } from '@base/common/hooks/auth-hook';

const ProductsModule: React.FC = () => {
  const { authInfo } = useStatesGlobalsStore();
  const { logout }: any = useAuth();

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    FetchApiGet(authInfo, logout);
  }, [authInfo.accessToken]);

  return (
    <div data-testid='productsPage' className='bg-white p-5'>
      <Typography variant='h1' component='h2' gutterBottom>
        Products
      </Typography>

      <ProductsList />
    </div>
  );
};

export default ProductsModule;
