import { ApolloError } from '@apollo/client';
import {
  IOrderDetailVars,
  useGetOrderDetailGraphql,
} from 'api/endPoints/removalOrders/getOrderDetail';
import { getErrorTransform } from 'api/transforms/errors';
import {
  getOrderDetailTransform,
  IServiceResponse,
} from 'api/transforms/OrderDetail';

interface IServiceState {
  loading: boolean;
  error: ApolloError | string | undefined;
  response: IServiceResponse | undefined;
}

const useGetOrderDetail = (endpointParams: IOrderDetailVars): IServiceState => {
  const { loading, error, data } = useGetOrderDetailGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: getOrderDetailTransform(data),
    };
  } else {
    return { loading, error: getErrorTransform(error), response: data };
  }
};

export default useGetOrderDetail;
