import {
  IOrderDetailExternal,
  IOrderDetailInternal,
} from 'interfaces/OrderDetail';

export interface IServiceResponse {
  data: IOrderDetailInternal;
}

export const getOrderDetailTransform = (
  data: IOrderDetailExternal,
): IServiceResponse => {
  const transformData = data.ordersOperatorsDetail.data;
  return { data: transformData };
};
