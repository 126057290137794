import { useStore } from '../store/dataTableStore';

const useDataTableStore = () => {
  const order = useStore.useOrder();
  const setOrder = useStore.useSetOrder();

  const orderBy = useStore.useOrderBy();
  const setOrderBy = useStore.useSetOrderBy();

  const dense = useStore.useDense();
  const setDense = useStore.useSetDense();

  const dataUser = useStore.useDataUser();
  const setDataUser = useStore.useSetDataUser();

  const loading = useStore.useLoading();
  const setLoading = useStore.useSetLoading();

  const pageStore = useStore.usePageStore();
  const setPageStore = useStore.useSetPageStore();

  const rowPerPageStore = useStore.useRowPerPageStore();
  const setRowPerPageStore = useStore.useSetRowPerPageStore();

  return {
    order,
    orderBy,
    dense,
    dataUser,
    loading,
    pageStore,
    rowPerPageStore,

    setOrder,
    setOrderBy,
    setDense,
    setDataUser,
    setLoading,
    setPageStore,
    setRowPerPageStore,
  };
};

export default useDataTableStore;
