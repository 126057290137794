import React, { useContext } from 'react';
import Minimal from '@base/layout/Minimal/Minimal';
import Main from '@base/layout/Main/Main';
import SignUp from '@base/pages/SignUp';
import SignIn from '@base/pages/SignIn';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import useAuthContext from '@base/common/hooks/auth-context';
import VerifyRoleComponent from 'components/UI/verifyRoleComponent';

const Dashboard = React.lazy(() => import('@base/pages/Dashboard'));
const ForgotPassword = React.lazy(() => import('@base/pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('@base/pages/ResetPassword'));
const Account = React.lazy(() => import('@base/pages/Account'));

export function PublicRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Minimal>{React.cloneElement(children, props)}</Minimal>
      )}
    />
  );
}

export function PrivateRoute({ children, ...rest }) {
  const auth = useAuthContext();

  let routes;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const key = params.get('key');
  let dashboard = '/dashboard';

  if (key) {
    dashboard = '/dashboard?key=' + key;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.stripeStatus &&
        auth.stripeStatus.status === 'canceled' &&
        auth.isLoggedIn ? (
          <Redirect to='/account?billing=true' />
        ) : auth.isLoggedIn ? (
          <Main tenantname={auth.tenant}>
            {React.cloneElement(children, props)}
          </Main>
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export function ControlledRoute({ children, ...rest }) {
  const auth = useAuthContext();

  let routes;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const key = params.get('key');
  let dashboard = '/dashboard';

  if (key) {
    dashboard = '/dashboard?key=' + key;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.stripeStatus &&
        auth.stripeStatus.status === 'canceled' &&
        auth.isLoggedIn ? (
          <Redirect to='/account?billing=true' />
        ) : auth.isLoggedIn ? (
          <Main tenantname={auth.tenant}>
            <VerifyRoleComponent
              component={React.cloneElement(children, props)}
            />
          </Main>
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

const RoutesBase = ({ children }) => {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path='/'>
          <SignIn />
        </PublicRoute>
        <PublicRoute exact path='/sign-in'>
          <SignIn />
        </PublicRoute>
        <PublicRoute exact path='/sign-up'>
          <SignUp />
        </PublicRoute>
        <PublicRoute exact path='/ForgotPassword'>
          <ForgotPassword />
        </PublicRoute>
        <PublicRoute exact path='/resetpw'>
          <ResetPassword />
        </PublicRoute>

        <PrivateRoute exact path='/dashboard'>
          <Dashboard />
        </PrivateRoute>
        <ControlledRoute exact path='/account'>
          <Account />
        </ControlledRoute>

        {children}

        <Route>
          <div>error 404</div>
        </Route>
      </Switch>
    </Router>
  );
};

export default RoutesBase;
