import { ApolloError, useQuery } from '@apollo/client';
import { GET_PRODUCTS } from './queries';
import { IProducts } from 'interfaces/products';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: IProducts | undefined;
}

export interface IProductFilters {
  asin?: string;
  fnsku?: string;
  sku?: string;
}

export interface IProductsVars {
  reqPage: number;
  reqLimit: number;
  filters: IProductFilters;
}

export const useGetProdutsGraphql = ({
  reqPage,
  reqLimit,
  filters,
}: IProductsVars): IEndpointResponse => {
  const { loading, error, data } = useQuery<IProducts, IProductsVars>(
    GET_PRODUCTS,
    {
      variables: {
        reqPage,
        reqLimit,
        filters,
      },
    },
  );

  return {
    loading,
    error,
    data,
  };
};
