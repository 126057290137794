import React from 'react';
import Alert from '@material-ui/lab/Alert';
import FilterSection from 'components/UI/filters/FilterSection';
import useGetRemovalOrders from 'api/services/removalOrders/get';
import DataTableTest from 'components/UI/DataTable/containers/dataTableTest';
import useOptionsPage from 'hooks/useOptionsPage';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { propertyTitleRemoval } from '../constants/index';
import {
  dataCollapseRemoval,
  dataFieldsCollapseRemoval,
  dataHeaders,
  dataTableFields,
  dataTableRowsPerPage,
  inputsFilters,
  selectFilters,
  titleCollapseRemoval,
} from '../constants';
import { useLocation } from 'react-router-dom';
import { IPaginationState } from 'components/UI/DataTable/containers/DataTable';

interface IFilterState {
  orderId: string;
  trackingNumber: string | [];
  mwsSellerId: string;
  company: string;
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  orderStatus: string | unknown;
  internalStatus: string | unknown;
}

const RemovalOrdersList: React.FC = () => {
  const { state: location } = useLocation<IPaginationState>();
  const { page, setPage, rowsPerPage, setRowsPerPage, validatePage } =
    useOptionsPage();
  const [filterOptions, setFilterOptions] = React.useState<IFilterState>({
    orderId: '',
    trackingNumber: [],
    mwsSellerId: '',
    company: '',
    customerFirstName: '',
    customerLastName: '',
    customerEmail: '',
    orderStatus: '',
    internalStatus: '',
  });
  const [FilterComplete, setFilterComplete] =
    React.useState<IFilterState>(filterOptions);

  const { loading, error, response } = useGetRemovalOrders({
    reqPage: page + 1,
    reqLimit: rowsPerPage,
    filters: FilterComplete,
  });

  React.useEffect(() => {
    setRowsPerPage(dataTableRowsPerPage);
  }, []);

  React.useEffect(() => {
    if (location?.paginationState) {
      setPage(location?.paginationState.page);
      setRowsPerPage(location?.paginationState.rowsPerPage);
    }
  }, [location?.paginationState]);

  if (error)
    return (
      <Alert data-testid='alertErrorService' severity='error'>
        {error}
      </Alert>
    );

  return (
    <>
      <FilterSection
        setFilterOptions={setFilterOptions}
        setFilterComplete={setFilterComplete}
        filterOptions={filterOptions}
        inputsFilters={inputsFilters}
        selectFilters={selectFilters}
        setPage={setPage}
      />
      <div className='mt-10'>
        <DataTableTest data={response?.data as any[]} loading={loading}>
          <DataTableTest.Container>
            <DataTableTest.Headers headers={dataHeaders} sortBy={'orderId'} />
            <DataTableTest.body>
              <DataTableTest.Row
                dataCollapse={dataCollapseRemoval}
                titleCollapse={titleCollapseRemoval}
                propertyTitle={propertyTitleRemoval}
                dataFields={dataTableFields}
                dataFieldsCollapse={dataFieldsCollapseRemoval}
                editComponent={(tracking: any) => {
                  return (
                    <ul>
                      {tracking?.map((item: any) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  );
                }}
              />
            </DataTableTest.body>
          </DataTableTest.Container>
          <DataTableTest.Pagination
            count={response?.meta.totalDocs || 0}
            page={validatePage(loading, page)}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
          <DataTableTest.DensenPadding />
        </DataTableTest>
      </div>
    </>
  );
};

export default RemovalOrdersList;

export const useStylesContainer = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
      flexDirection: 'row',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
      flex: 1,
    },
    rootSwitch: {
      margin: theme.spacing(2),
    },
    container: {
      display: 'flex',
    },
  }),
);
