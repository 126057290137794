import React from 'react'
import CircularProgess from '@material-ui/core/CircularProgress'; 
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const LoadingSpinner = props => {
    const classes = useStyles();

    return (
    <Backdrop className={classes.backdrop} open={props.open}>
    <CircularProgess color="inherit" />
  </Backdrop>
  );
}

export default LoadingSpinner; 