import { useStore } from '../store';

const useStatesGlobalsStore = () => {
  const authInfo = useStore.useAuthInfo();
  const setAuthInfo = useStore.useSetAuthInfo();

  return {
    authInfo,
    setAuthInfo,
  };
};

export default useStatesGlobalsStore;
