import React from 'react';
import Alert from '@material-ui/lab/Alert';
import useStatesGlobalsStore from 'hooks/useStatesGlobalsStore';

const VerifyRoleComponent = ({ component }: any) => {
  const { authInfo } = useStatesGlobalsStore();

  if (authInfo?.accountOwner === false) {
    return (
      <Alert severity='error'>You do not have permission to be here</Alert>
    );
  }

  if (authInfo?.accountOwner) {
    return <>{component}</>;
  }

  return <> </>;
};

export default VerifyRoleComponent;
