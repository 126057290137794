import {useState, useCallback, useRef, useEffect} from 'react'; 

export const useHttpClient = () => {
    const [isLoading, setIsLoading] = useState(false); 
    const [error, setError] = useState(); 
    const activeHttpRequests = useRef([]); 

    const sendRequest = useCallback(async (url, method = 'GET', body = null, headers={}) => {
       try
       {
           setIsLoading(true); 
           const httpAbortCtrl = new AbortController(); 
           activeHttpRequests.current.push(httpAbortCtrl); 
        const response = await fetch (url, {method,
            body,
            headers,
            signal: httpAbortCtrl.signal //handles cancelling connected request when moving. 
        }); 

        const responseData = await response.json();
        activeHttpRequests.current=activeHttpRequests.current.filter(reqCtrl => reqCtrl!==httpAbortCtrl);
        if (!response.ok){
            throw new Error(responseData.message);
        }
        setIsLoading(false); 
        return responseData; 
        }
        catch (err){
            setError(err.message);
            setIsLoading(false);  
            throw err; 
        }
    }

    ,[]); //empty array means function never gets recreated. 
        
    const clearError = () => {
        setError(null); 
    }
    useEffect(()=>{
        return () => {
            activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());

        }; //return function inside useffect acts as a destructor. 
    
    }, []); 
    return {isLoading, error, sendRequest, clearError}
};
