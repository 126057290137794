import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import validateAndProcessDates from './validateAndProcessDates';
import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { useStylesContainer } from 'modules/removalOrders/containers/List';

export interface IFilterProps {
  filterOptions: any;
  setFilterOptions: any;
  inputsFilters: any;
  selectFilters?: any;
  dateFilters?: any;
  isSelect?: boolean;
  setFilterComplete: any;
  isValidateDate?: boolean;
  setPage: (value: number) => void;
  numberFilters?: any;
}

const FilterSection: React.FC<IFilterProps> = ({
  filterOptions,
  setFilterOptions,
  inputsFilters,
  selectFilters,
  dateFilters,
  setFilterComplete,
  isValidateDate,
  setPage,
  numberFilters,
}) => {
  const classes = useStylesContainer();
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (isValidateDate) {
      validateAndProcessDates(filterOptions, setFilterComplete);
    } else {
      setFilterComplete(filterOptions);
    }
    setPage(0);
  };

  return (
    <div className={classes.rootSwitch}>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            data-testid='switchFilters'
          />
        }
        label='Filters'
      />
      {checked && (
        <>
          <div className={classes.container}>
            <Collapse in={checked}>
              <form
                onSubmit={onSubmit}
                className={`${classes.root} flex flex-wrap flex-col md:mb-16 sm:flex-row`}
                noValidate
                autoComplete='off'>
                {inputsFilters.map((input: any) => (
                  <div className='flex' key={input.id}>
                    <TextField
                      data-testid={input.name}
                      value={filterOptions[input.name]}
                      id={input.id}
                      name={input.name}
                      label={input.label}
                      onChange={(e) => {
                        if (input.isMultipleValueInput) {
                          const trackingsArray = e.target.value.split(',');

                          setFilterOptions({
                            ...filterOptions,
                            [e.target.name]: trackingsArray,
                          });
                        } else {
                          setFilterOptions({
                            ...filterOptions,
                            [e.target.name]: e.target.value.trim(),
                          });
                        }
                      }}
                    />

                    {filterOptions[input.name] !== '' &&
                      filterOptions[input.name].length > 0 && (
                        <ClearIcon
                          data-testid='bottonClearInput'
                          onClick={() =>
                            setFilterOptions({
                              ...filterOptions,
                              [input.name]: input.isMultipleValueInput
                                ? []
                                : '',
                            })
                          }
                          className='cursor-pointer'
                        />
                      )}
                  </div>
                ))}

                {selectFilters?.map((item: any) => (
                  <FormControl key={item.name} className={classes.formControl}>
                    <Select
                      value={filterOptions[item.name]}
                      name={item.name}
                      onChange={(e: any) =>
                        setFilterOptions({
                          ...filterOptions,
                          [e.target.name]: e.target.value,
                        })
                      }
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{
                        'aria-label': 'Without label',
                        'data-testid': `${item.name}`,
                      }}>
                      {item.menuItem.map((menuItems: any) => (
                        <MenuItem
                          key={menuItems.labelName}
                          value={menuItems.value}
                          disabled={menuItems.disabled}>
                          {menuItems.labelName}
                        </MenuItem>
                      ))}
                    </Select>
                    {filterOptions[item.name] !== '' && (
                      <ClearIcon
                        aria-hidden={false}
                        role='img'
                        titleAccess='meaning'
                        data-testid='bottonClearSelect'
                        className='cursor-pointer'
                        onClick={() =>
                          setFilterOptions({
                            ...filterOptions,
                            [item.name]: '',
                          })
                        }
                      />
                    )}
                  </FormControl>
                ))}

                {dateFilters && (
                  <>
                    {dateFilters.map((input: any) => (
                      <div className='flex' key={input.id}>
                        <TextField
                          value={filterOptions[input.name] || ''}
                          id={input.id}
                          name={input.name}
                          data-testid={input.name}
                          label={input.label}
                          type='date'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            setFilterOptions({
                              ...filterOptions,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />

                        {filterOptions[input.name] !== undefined &&
                          filterOptions[input.name] !== '' && (
                            <ClearIcon
                              data-testid='bottonClearInputDate'
                              onClick={() =>
                                setFilterOptions({
                                  ...filterOptions,
                                  [input.name]: undefined,
                                })
                              }
                              className='cursor-pointer'
                            />
                          )}
                      </div>
                    ))}
                  </>
                )}
                {numberFilters?.map((input: any) => (
                  <div className='flex' key={input.id}>
                    <TextField
                      data-testid={input.name}
                      value={
                        filterOptions[input.name] === null
                          ? ''
                          : filterOptions[input.name]
                      }
                      id={input.id}
                      name={input.name}
                      label={input.label}
                      type='number'
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) =>
                        setFilterOptions({
                          ...filterOptions,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    {filterOptions[input.name] !== null && (
                      <ClearIcon
                        data-testid='bottonClearInputNumber'
                        onClick={() =>
                          setFilterOptions({
                            ...filterOptions,
                            [input.name]: null,
                          })
                        }
                        className='cursor-pointer'
                      />
                    )}
                  </div>
                ))}

                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  data-testid='buttonFilter'>
                  Filter
                </Button>
              </form>
            </Collapse>
          </div>
        </>
      )}
    </div>
  );
};

export default FilterSection;
