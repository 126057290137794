import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  email: null,
  tenant: null,
  error: null,
  authProvider: null,
  stripeStatus: null,
  token: null,
  login: () => {},
  logout: () => {},
  clearError: () => {},
  updateSettings: () => {},
  logoutButton: () => {},
});
