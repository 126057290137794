import React from 'react';
import useDateFormat from 'hooks/useDateFormat';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ButtonColapse from './ButtonColapse';
import EnhancedTableHead from './EnhancedTableHead';
import StatusChip from 'components/UI/StatusChip';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import useValidateCondition from 'hooks/useValidateCondition';
import usePriceFormat from 'hooks/usePriceFormat';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Collapse,
  DialogContent,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CollapseTracking from 'modules/removalOrders/containers/components/CollapseTracking';
import ModalLogsNewAsin from 'modules/products/containers/modalLogsNewAsin';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      borderBottom: 'unset',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    imageList: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
    },
    paperModal: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

export interface IRowCollapseProps {
  row: any;
  options?: any;
  optionsCollapse?: any;
  dataCollapse?: any;
  titleProperty?: string;
  isCondition?: boolean;
  setField?: any;
  verifyProperty?: string;
  ComponentsView?: any;
  page?: number;
  rowsPerPage?: number;
  editComponent?: any;
  statusBox?: any;
}

const RowCollapse: React.FC<IRowCollapseProps> = ({
  row,
  options,
  optionsCollapse,
  dataCollapse,
  titleProperty,
  page,
  rowsPerPage,
  editComponent,
  statusBox,
}) => {
  const classes = useRowStyles();
  const colSpan = Number(options.length) + 1 || 8;
  const [open, setOpen] = React.useState(false);
  const [openModalImge, setOpenModalImage] = React.useState(false);
  const [arrayPhotos, setArrayphotos] = React.useState([]);

  const handleOpen = (item: any) => {
    setOpenModalImage(true);
    setArrayphotos(item.productPhotos);
  };

  const handleClose = () => {
    setOpenModalImage(false);
    setArrayphotos([]);
  };

  const VerifyTableCell = ({ rowVerifyTable, option }: any) => {
    const verifyTypeRow = () => {
      if (option.isLogs && rowVerifyTable.newAsin) {
        return <ModalLogsNewAsin item={rowVerifyTable} />;
      }

      if (rowVerifyTable.newAsin && option.newAsin) {
        return rowVerifyTable.newAsin;
      }

      if (option.withChip) {
        return (
          <StatusChip
            type={rowVerifyTable[option.field]}
            textTooltip={option.textTooltip}
          />
        );
      }
      if (option.isPhoto) {
        return editComponent(rowVerifyTable[option.field]);
      }
      if (option.isEditState) {
        return editComponent();
      }
      if (option.isStatusBox) {
        return statusBox(rowVerifyTable[option.field]);
      }
      if (option.isDate) {
        return useDateFormat(rowVerifyTable[option.field]);
      }
      if (option.isCondition) {
        return useValidateCondition(rowVerifyTable[option.field]);
      }
      if (option.field === 'ItemPrice') {
        return usePriceFormat(rowVerifyTable.ItemPrice?.Amount);
      }
      if (option.isPrice) {
        return usePriceFormat(rowVerifyTable[option.field]);
      }
      if (option.isItemCollapse) {
        return (
          <CollapseTracking rowVerifyTable={rowVerifyTable} option={option} />
        );
      }
      if (option.field === 'asin' || option.field === 'ASIN') {
        return (
          <Tooltip
            aria-labelledby='seeProduct'
            title={<p className='text-base'>See product on Amazon</p>}
            placement='bottom-start'>
            <a
              className='hover:text-blue-500 flex items-end'
              rel='noreferrer'
              target='_blank'
              href={`https://www.amazon.com/dp/${
                rowVerifyTable[option.field]
              }`}>
              <p>{rowVerifyTable[option.field]}</p>
              <img
                id='amazonImage'
                src='/images/iconAmazon.png'
                alt='icon Amazon'
                className='w-6 mx-3 cursor-pointer'
              />
            </a>
          </Tooltip>
        );
      }
      return rowVerifyTable[option.field];
    };
    return (
      <TableCell
        width={option.width || 'auto'}
        align='left'
        component='th'
        scope='row'>
        {verifyTypeRow()}
      </TableCell>
    );
  };

  const VerifyTableCellCollapse = ({ rowVerifyTable, option }: any) => {
    const verifyTypeRow = () => {
      if (option.isDate) {
        return useDateFormat(rowVerifyTable[option.field]);
      }
      if (option.isCondition) {
        return useValidateCondition(rowVerifyTable[option.field]);
      }
      if (option.isTrackingCollapse) {
        return (
          <>
            <CollapseTracking rowVerifyTable={rowVerifyTable} option={option} />
          </>
        );
      }
      if (option.field === 'ItemPrice') {
        return usePriceFormat(rowVerifyTable.ItemPrice?.Amount);
      }
      if (option.isPrice) {
        return usePriceFormat(rowVerifyTable[option.field]);
      }
      if (option.field === 'trackingNumber') {
        return editComponent(rowVerifyTable[option.field]);
      }
      if (option.field === 'asin' || option.field === 'ASIN') {
        return (
          <Tooltip
            title={<p className='text-base'>See product on Amazon</p>}
            placement='bottom-start'>
            <a
              className='hover:text-blue-500 flex items-end'
              rel='noreferrer'
              target='_blank'
              href={`https://www.amazon.com/dp/${
                rowVerifyTable[option.field]
              }`}>
              <p>{rowVerifyTable[option.field]}</p>
              <img
                src='/images/iconAmazon.png'
                alt='icon Amazon'
                className='w-6 mx-3 cursor-pointer'
              />
            </a>
          </Tooltip>
        );
      }
      return rowVerifyTable[option.field];
    };
    return (
      <TableCell
        width={option.width || 'auto'}
        align='left'
        component='th'
        scope='row'>
        {verifyTypeRow()}
      </TableCell>
    );
  };

  return (
    <React.Fragment>
      <>
        <TableRow className={classes.root} key={row.id}>
          {dataCollapse && (
            <TableCell>
              {dataCollapse?.length === 0 ? (
                <Tooltip title='There are no associated items'>
                  <span>
                    <ButtonColapse
                      open={open}
                      setOpen={setOpen}
                      disabled
                      testId={'buttonCollapseDisabled'}
                    />
                  </span>
                </Tooltip>
              ) : (
                <ButtonColapse
                  open={open}
                  setOpen={setOpen}
                  disabled={false}
                  testId={'buttonCollapse'}
                />
              )}
            </TableCell>
          )}

          {options?.map((option: any, index: any) => (
            <React.Fragment key={index}>
              <>
                {option.field !== '' ? (
                  <VerifyTableCell option={option} rowVerifyTable={row} />
                ) : (
                  <TableCell component='th' scope='row' className='flex'>
                    <Link
                      to={{
                        pathname: `/removal-orders/${row.id}`,
                        state: { page, rowsPerPage },
                      }}>
                      <Button
                        data-testid='detailButton'
                        variant='contained'
                        color='primary'>
                        Detail
                      </Button>
                    </Link>
                  </TableCell>
                )}
              </>
            </React.Fragment>
          ))}
        </TableRow>
        {dataCollapse && (
          <TableRow>
            <TableCell
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={colSpan}>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box margin={1}>
                  <Typography variant='h6' gutterBottom component='div'>
                    {titleProperty}
                  </Typography>
                  <Table size='medium' aria-label='purchases'>
                    <EnhancedTableHead
                      headCells={optionsCollapse}
                      classes={classes}
                      onRequestSort={optionsCollapse}
                    />
                    <TableBody>
                      {dataCollapse?.map((item: any, index: any) => (
                        <TableRow key={index}>
                          <>
                            {optionsCollapse?.map(
                              (option: any, optionIndex: any) => (
                                <React.Fragment key={optionIndex}>
                                  {option.field !== 'productPhotos' ? (
                                    <VerifyTableCellCollapse
                                      option={option}
                                      rowVerifyTable={item}
                                    />
                                  ) : (
                                    <>
                                      <TableCell component='th' scope='row'>
                                        <Button
                                          style={{
                                            backgroundColor: 'green',
                                          }}
                                          variant='contained'
                                          color='primary'
                                          onClick={() => handleOpen(item)}>
                                          <BurstModeIcon
                                            className='mr-2'
                                            style={{
                                              fontSize: '2.3rem',
                                            }}
                                          />
                                          See images
                                        </Button>
                                        <Dialog
                                          fullWidth
                                          maxWidth='md'
                                          open={openModalImge}
                                          onClose={handleClose}
                                          data-testid='modalPhotos'
                                          aria-labelledby='simple-modal-title'
                                          aria-describedby='simple-modal-description'>
                                          <DialogContent>
                                            <Carousel>
                                              {arrayPhotos.map(
                                                (
                                                  photo: string,
                                                  indexPhoto: any,
                                                ) => (
                                                  <div key={indexPhoto}>
                                                    <img src={photo} />
                                                  </div>
                                                ),
                                              )}
                                            </Carousel>
                                          </DialogContent>
                                        </Dialog>
                                      </TableCell>
                                    </>
                                  )}
                                </React.Fragment>
                              ),
                            )}
                          </>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    </React.Fragment>
  );
};

export default RowCollapse;
