import React, { useContext, useState } from 'react';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Topbar from './components/Topbar';
import { sidebarViewStatus } from '@base/common/context/SidebarViewStatusContext';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContentSidebarTrue: {
    paddingLeft: 205,
  },
  shiftContentSidebarFalse: {
    paddingLeft: 80,
  },
  content: {
    height: '100%',
  },
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const { openSidebarDynamic } = useContext(sidebarViewStatus);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [openSidebarDynamic
          ? classes.shiftContentSidebarTrue
          : classes.shiftContentSidebarFalse]: isDesktop,
      })}>
      <Topbar onSidebarOpen={handleSidebarOpen} />

      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
        tenantname={props.tenantname}
      />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  );
};

export default Main;
