import React from 'react';
import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface IDisabled {
  open: boolean;
  setOpen: (open: boolean) => void;
  disabled: boolean;
  testId: string;
}

const ButtonColapse: React.FC<IDisabled> = ({
  open,
  setOpen,
  disabled,
  testId,
}) => {
  return (
    <IconButton
      onClick={() => setOpen(!open)}
      data-testid={testId}
      aria-label='expand row'
      disabled={disabled}
      size='small'>
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};

export default ButtonColapse;
