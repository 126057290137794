import React, { createContext } from 'react';

export const sidebarViewStatus = createContext();

const SidebarViewStatusContext = (props) => {
  const [openSidebarDynamic, setOpenSidebarDiynamic] = React.useState(true);

  const controlSidebar = () => {
    setOpenSidebarDiynamic(!openSidebarDynamic);
  };

  return (
    <sidebarViewStatus.Provider
      value={{ openSidebarDynamic, setOpenSidebarDiynamic, controlSidebar }}>
      {props.children}
    </sidebarViewStatus.Provider>
  );
};

export default SidebarViewStatusContext;
