import { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import useStatesGlobalsStore from 'hooks/useStatesGlobalsStore';
import { gql, ApolloClient, InMemoryCache } from '@apollo/client';
import FetchApiLogout from 'hooks/useLogout';
import { async } from 'validate.js';
import PostFetchLogout from 'hooks/useLogout';

let logOutTimer;

export const useAuth = () => {
  const { authInfo, setAuthInfo } = useStatesGlobalsStore();

  const [token, setToken] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.token || null;
  });
  const [userId, setUserId] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.userId || null;
  });
  const [email, setEmail] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.email || null;
  });
  const [tenant, setTenant] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.tenant || null;
  });
  const [error, setError] = useState(); // error from social

  const [authProvider, setAuthProvider] = useState(() => {
    const authLocal = localStorage.getItem('userData');
    return JSON.parse(authLocal)?.authProvider || false;
  }); //false if 3rd party
  const [stripeStatus, setStripeStatus] = useState(); //status object for Stripe.
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback(
    (uid, email, tenant, token, expirationDate, provider, status) => {
      setToken(token);
      setEmail(email);
      setTenant(tenant);
      setStripeStatus(status);
      setAuthProvider(provider);
      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 1440); //one hour timeout.
      setTokenExpirationDate(tokenExpirationDate);
      localStorage.setItem(
        'userData',
        JSON.stringify({
          userId: uid,
          email: email,
          tenant: tenant,
          token: token,
          expiration: tokenExpirationDate.toISOString(),
          authProvider: provider,
          status: status,
        }),
      ); //save to token to local storage.
      setUserId(uid);
    },
    [],
  );

  const updateSettings = useCallback((company, email, stripe) => {
    if (company) setTenant(company);
    if (email) setEmail(email);
    if (stripe) setStripeStatus(stripe);
  }, []);

  const userData = localStorage.getItem('localStore');

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    localStorage.removeItem('userData');
    setAuthInfo([]);
    window.location.href =
      'https://returns-53785.bubbleapps.io/operators_login';
  }, []);

  const logoutButton = async () => {
    if (authInfo.accessToken) {
      const data = {
        username: authInfo.username,
      };

      const response = await PostFetchLogout(authInfo, data);

      logout();
    }
  };

  const clearError = useCallback((value) => {
    setError(value);
  }, []);

  useEffect(() => {
    //auto login if token exists.
    let failData = Cookies.get('fail');
    if (failData) {
      //social auth returned an error that needs to be displayed
      setError(failData);
      Cookies.set('fail', 'test', {
        path: '/',
        domain: process.env.REACT_APP_COOKIEDOMAIN,
        expires: -7,
      });
    }
    let storedData = Cookies.get('auth'); //check for cookie sent by oauth providers
    if (storedData) {
      storedData = JSON.parse(storedData.replace('j:', ''));
      storedData.expiration = new Date(new Date().getTime() + 1000 * 60 * 1440); //one hour timeout
      storedData.authProvider = false;
      Cookies.set('auth', 'test', {
        path: '/',
        domain: process.env.REACT_APP_COOKIEDOMAIN,
        expires: -7,
      });
    }
    if (!storedData) storedData = JSON.parse(localStorage.getItem('userData')); // convert json to object
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.email,
        storedData.tenant,
        storedData.token,
        new Date(storedData.expiration),
        storedData.authProvider,
        storedData.status,
      ); //login
    }
  }, [login]); //run once after render because use callback it will only run once.

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logOutTimer = setTimeout(logout, remainingTime); //trigger logout when we run out of time.
    } else {
      clearTimeout(logOutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  return {
    token,
    userId,
    email,
    tenant,
    error,
    login,
    logout,
    clearError,
    updateSettings,
    authProvider,
    stripeStatus,
    logoutButton,
  };
};
