import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {
  dataBoxDetailsCollapse,
  dataHeadersBoxDetails,
  dataHeadersDetail,
  itemsHeaders,
} from 'modules/removalOrders/constants/Detail';
import DataTableTest from 'components/UI/DataTable/containers/dataTableTest';
import useGetOrderDetail from 'api/services/removalOrders/getOrderDetail';
import {
  dataCollapseDetails,
  titleCollapseDetails,
} from '../../constants/Detail';
import { useParams } from 'react-router-dom';
import StatusBox from 'components/UI/StatusBox/StatusBox';
import { TableCell } from '@material-ui/core';

const DataTableDetails: React.FC = () => {
  const [value, setValue] = React.useState('1');
  const [page, setPage] = React.useState(1);
  const [rowPerPage, setRowsPerPage] = React.useState(5);

  const { id: urlIdOrderDetail } = useParams<any>();

  const { loading, response } = useGetOrderDetail({
    _id: urlIdOrderDetail,
  });

  const handleChange = (_event: any, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className='flex-grow mt-10'>
      <TabContext value={value}>
        <AppBar position='static'>
          <TabList onChange={handleChange} aria-label='simple tabs example'>
            <Tab label='Items' value='1' />
            <Tab label='Box Details' value='2' />
          </TabList>
        </AppBar>
        <TabPanel value='1'>
          <div data-testid='itemsTab'>
            <DataTableTest data={response?.data.items as any} loading={loading}>
              <DataTableTest.Container>
                <DataTableTest.Headers
                  headers={itemsHeaders}
                  sortBy={'FNSKU'}
                />
                <DataTableTest.body>
                  <DataTableTest.Row dataFields={itemsHeaders} />
                </DataTableTest.body>
              </DataTableTest.Container>

              <DataTableTest.DensenPadding />
            </DataTableTest>
          </div>
        </TabPanel>
        <TabPanel value='2'>
          <div data-testid='boxDetailsTab'>
            <DataTableTest
              data={response?.data.received as any}
              loading={loading}>
              <DataTableTest.Container>
                <DataTableTest.Headers
                  headers={dataHeadersDetail}
                  sortBy={'orderId'}
                />
                <DataTableTest.body>
                  <DataTableTest.Row
                    dataCollapse={dataCollapseDetails}
                    titleCollapse={titleCollapseDetails}
                    dataFields={dataHeadersBoxDetails}
                    dataFieldsCollapse={dataBoxDetailsCollapse}
                    editComponent={(row: any) => {
                      return (
                        <TableCell
                          align='left'
                          component='th'
                          scope='row'
                          className='w-56'>
                          <img src={row} alt='photo' />
                        </TableCell>
                      );
                    }}
                    statusBox={(status: string) => {
                      return (
                        <>
                          <div className='w-40'>
                            <StatusBox boxStatus={status} />
                          </div>
                        </>
                      );
                    }}
                  />
                </DataTableTest.body>
              </DataTableTest.Container>
              <DataTableTest.Pagination
                count={0}
                page={page}
                setPage={setPage}
                rowsPerPage={rowPerPage}
                setRowsPerPage={setRowsPerPage}
              />
              <DataTableTest.DensenPadding />
            </DataTableTest>
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default DataTableDetails;
