const useValidateCondition = (conditionKey = 'none') => {
  const conditions: any = {
    NewItem: 'New Item',
    NewOpenBox: 'New Open Box',
    UsedLikeNew: 'Used Like New',
    UsedVeryGood: 'Used Very Good',
    UsedGood: 'Used Good',
    UsedAcceptable: 'Used Acceptable',
    UsedPoor: 'Used Poor',
    Refurbished: 'Refurbished',
    MissingParts: 'Missing Parts',
  };

  return conditions[conditionKey];
};

export default useValidateCondition;
