import { gql } from '@apollo/client';

export const GET_VERIFY_COGNITO_TOKEN = gql`
  query MyQuery($token: String!, $project: String!) {
    userValidateToken(token: $token, project: $project) {
      status
      userInfo {
        accessToken
        _id
        accountOwner
        email
        linkedAccounts
        status
        userInternalId
        username
      }
    }
  }
`;
