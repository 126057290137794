import React from 'react';
import { TreeView, TreeItem } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

interface Collapse {
  rowVerifyTable: any;
  option: any;
}
const CollapseTracking: React.FC<Collapse> = ({ rowVerifyTable, option }) => {
  const trackings = rowVerifyTable[option?.field];

  const verifyTracking = Array.isArray(trackings);

  return (
    <>
      {verifyTracking && (
        <>
          {trackings?.slice(0, 2)?.map((tracking: string) => {
            return <div key={tracking}>{tracking}</div>;
          })}

          {trackings?.length > 2 && (
            <>
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}>
                <TreeItem label='View More' key='1' nodeId='1'>
                  {rowVerifyTable[option.field]?.map(
                    (tracking: string, index: number) => {
                      if (index > 1) {
                        return <div key={tracking}>{tracking}</div>;
                      }
                    },
                  )}
                </TreeItem>
              </TreeView>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CollapseTracking;
