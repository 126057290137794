import { useState, useEffect } from 'react';

export const usePermission = (sendRequest, auth) => {
  const [permission, setPermission] = useState(undefined);

  useEffect(() => {
    //get permission level for protected subpages.
    const getPerms = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/api/users/permissions',
          'GET',
          null,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          },
        );
        setPermission(responseData.owner);
      } catch (err) {}
    };
    getPerms();
  }, [auth.token, sendRequest]);
  return permission;
};
