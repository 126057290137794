import toast from 'react-hot-toast';

const validateAndProcessDates = (
  filterOptionsState: any,
  setFilterCompleteState: any,
) => {
  if (filterOptionsState.dateStart !== undefined) {
    if (filterOptionsState.dateEnd !== undefined) {
      if (
        Date.parse(filterOptionsState.dateStart || '') <=
        Date.parse(filterOptionsState.dateEnd || '')
      ) {
        setFilterCompleteState({
          ...filterOptionsState,
          dateStart: new Date(filterOptionsState.dateStart || '').toISOString(),
          dateEnd: new Date(filterOptionsState.dateEnd || '').toISOString(),
        });
      } else {
        toast.error('End date should be greater than the Start date');
      }
    } else {
      setFilterCompleteState({
        ...filterOptionsState,
        dateStart: new Date(filterOptionsState.dateStart || '').toISOString(),
        dateEnd: undefined,
      });
    }
  } else if (filterOptionsState.dateEnd !== undefined) {
    toast.error('You must enter a start date');
  } else if (filterOptionsState.PurchaseDate !== undefined) {
    setFilterCompleteState({
      ...filterOptionsState,
      PurchaseDate: new Date(
        filterOptionsState.PurchaseDate || '',
      ).toISOString(),
    });
  } else {
    setFilterCompleteState(filterOptionsState);
  }
  return false;
};

export default validateAndProcessDates;
