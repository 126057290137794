export const dataTableFields = [
  {
    field: 'asin',
    headerName: 'ASIN',
    sort: false,
    newAsin: true,
  },
  {
    field: 'itemName',
    headerName: 'Product Name',
    sort: false,
    width: '450',
  },
  {
    field: 'internalFnsku',
    headerName: 'OWN FNSKU',
    sort: false,
    isItemCollapse: true,
  },
  {
    field: 'internalSku',
    headerName: 'OWN SKU',
    sort: false,
    isItemCollapse: true,
  },
  // {
  //   field: 'company',
  //   headerName: 'Company',
  //   sort: false,
  //   isItemCollapse: true,
  // },
  {
    field: 'logs',
    headerName: 'Logs',
    sort: false,
    isLogs: true,
  },
];

export const dataTableFieldsLogs = [
  {
    field: 'asin',
    headerName: 'Asin',
    sort: false,
  },
  {
    field: 'operatorName',
    headerName: 'OperatorName',
    sort: false,
  },
  {
    field: 'updatedAt',
    headerName: 'UpdatedAt',
    sort: false,
    isDate: true,
  },
];

export const inputsFilters = [
  { id: 'asin', name: 'asin', label: 'ASIN' },
  { id: 'itemName', name: 'itemName', label: 'Item Name' },
  { id: 'customerFnsku', name: 'customerFnsku', label: 'Customer FNSKU' },
  { id: 'internalFnsku', name: 'internalFnsku', label: 'OWN FNSKU' },
  { id: 'customerSku', name: 'customerSku', label: 'Customer SKU' },
  { id: 'internalSku', name: 'internalSku', label: 'OWN SKU' },
  { id: 'company', name: 'company', label: 'Company' },
];

export const dataTableRowsPerPage = 10;
