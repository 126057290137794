import { IProducts, IDataProducts } from 'interfaces/products';

export interface IServiceResponse {
  meta: {
    totalDocs: number;
    totalPages: number;
  };
  data: IDataProducts[];
}

export const getProductsTransform = (data: IProducts): IServiceResponse => {
  const dataTable = data.productsGetList.data;

  const meta = {
    totalDocs: data.productsGetList.totalDocs,
    totalPages: data.productsGetList.totalPages,
  };

  return { meta, data: dataTable };
};
