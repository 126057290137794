import { getErrorTransform } from './../../transforms/errors';
import { ApolloError } from '@apollo/client';
import {
  IProductsVars,
  useGetLogsNewAsinGraphql,
} from 'api/endPoints/products/getLogsNewAsin';
import {
  getNewAsinLogsTransform,
  IServiceResponse,
} from 'api/transforms/getNewAsinLogsTransform';

interface IServiceState {
  loading: boolean;
  error: ApolloError | string | undefined;
  response: IServiceResponse | undefined;
}

const useGetLogsNewAsin = (endpointParams: IProductsVars): IServiceState => {
  const { loading, error, data } = useGetLogsNewAsinGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: getNewAsinLogsTransform(data),
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
    };
  }
};

export default useGetLogsNewAsin;
