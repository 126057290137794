import {
  IRemovalOrdersExternal,
  IRemovalOrdersInternal,
} from 'interfaces/RemovalOrders';

export interface IServiceResponse {
  meta: {
    totalDocs: number;
    totalPages: number;
  };
  data: IRemovalOrdersInternal[];
}

export const getRemovalOrdersTransform = (
  data: IRemovalOrdersExternal,
): IServiceResponse => {
  const dataWithId = data.ordersOperators.data.map((item) => {
    return { ...item, id: item._id };
  });

  const meta = {
    totalDocs: data.ordersOperators.totalDocs,
    totalPages: data.ordersOperators.totalPages,
  };

  return { meta, data: dataWithId };
};
