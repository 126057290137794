import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { AuthContext } from '@base/common/context/auth-context';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const logoutHandler = (event) => {
    event.preventDefault();
    auth.logoutButton();
  };

  const [notifications] = useState([]);

  return (
    <React.Fragment>
      <AppBar {...rest} className={clsx(classes.root, className)}>
        <Toolbar>
          <RouterLink
            to='/dashboard'
            style={{ textAlign: 'center', alignItems: 'center' }}>
            {/* <img
              alt="Logo"
              src="/images/logo-returns.jpg"
              height="60px"
              width="60px"
            /> */}
            <p
              style={{
                color: '#fff',
                fontSize: '1.2rem',
                marginBottom: '0.5rem',
                fontFamily: 'Roboto, "Helvetica", "Arial", sans-serif',
              }}>
              Returns Operators
            </p>
          </RouterLink>
          <div className={classes.flexGrow} />
          <Hidden mdDown>
            <IconButton color='inherit'>
              <Badge
                badgeContent={notifications.length}
                color='primary'
                variant='dot'>
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              className={classes.signOutButton}
              color='inherit'
              onClick={logoutHandler}>
              <InputIcon />
            </IconButton>
          </Hidden>
          <Hidden lgUp>
            <IconButton color='inherit' onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Topbar;
