import React, { Suspense } from 'react';
import theme from '@base/theme';
import LoadingSpinner from '@base/common/UI/LoadingSpinner';
import validate from 'validate.js';
import validators from '@base/common/validators';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';
import { useAuth } from '@base/common/hooks/auth-hook';
import { AuthContext } from '@base/common/context/auth-context';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Toaster } from 'react-hot-toast';

import '@base/assets/scss/index.scss';

import Routes from 'Routes';
import SidebarViewStatusContext from '@base/common/context/SidebarViewStatusContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE || '');

validate.validators = {
  ...validate.validators,
  ...validators,
};

const App: React.FC = () => {
  const {
    token,
    userId,
    email,
    tenant,
    error,
    login,
    logout,
    clearError,
    updateSettings,
    authProvider,
    stripeStatus,
    logoutButton,
  }: any = useAuth();

  return (
    <Elements stripe={stripePromise}>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          userId,
          email,
          tenant,
          error,
          token,
          login,
          logout,
          clearError,
          updateSettings,
          authProvider,
          stripeStatus,
          logoutButton,
        }}>
        <SidebarViewStatusContext>
          <ThemeProvider theme={theme}>
            <Toaster
              toastOptions={{
                duration: 5000,
              }}
            />
            <BrowserRouter>
              <Suspense
                fallback={
                  <div>
                    <LoadingSpinner open />
                  </div>
                }>
                <Routes />
              </Suspense>
            </BrowserRouter>
          </ThemeProvider>
        </SidebarViewStatusContext>
      </AuthContext.Provider>
    </Elements>
  );
};

export default App;
