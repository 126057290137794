import React from 'react';
import {
  FormControlLabel,
  LinearProgress,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Switch,
} from '@material-ui/core';
import EnhancedTableHead from '../components/EnhancedTableHead';
import RowCollapse from '../components/RowCollapse';
import NoData from '../components/NoData';
import useDataTableStore from '../hooks/useDataTableStore';

export default function DataTableTest({
  children,
  data,
  loading,
  ...restProps
}: any) {
  const { setDataUser, setLoading } = useDataTableStore();

  React.useEffect(() => {
    setDataUser(data);
    setLoading(loading);
  }, [data]);

  return <TableContainer {...restProps}>{children}</TableContainer>;
}

DataTableTest.Container = function DataTableContainer({
  children,
  ...restProps
}: any) {
  const { dense, loading, dataUser } = useDataTableStore();

  if (loading) {
    return (
      <>
        <Table
          aria-label='collapsible table'
          size={dense ? 'small' : 'medium'}
          {...restProps}>
          {children}
        </Table>
        <div
          data-testid='loadingDataTableStatus'
          className='w-full'
          {...restProps}>
          <LinearProgress
            color='primary'
            aria-label='Loading removal returns'
          />
        </div>
      </>
    );
  }

  if ((!loading && dataUser?.length === 0) || dataUser === undefined) {
    return (
      <>
        <Table
          aria-label='collapsible table'
          size={dense ? 'small' : 'medium'}
          {...restProps}>
          {children}
        </Table>
        <NoData message='No results found' />
      </>
    );
  } else {
    return (
      <Table
        aria-label='collapsible table'
        size={dense ? 'small' : 'medium'}
        {...restProps}>
        {children}
      </Table>
    );
  }
};

DataTableTest.Headers = function DataTableHeaders({
  headers,
  sortBy,
  ...restProps
}: any) {
  const { order, setOrder, orderBy, setOrderBy } = useDataTableStore();

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    setOrderBy(sortBy);
  }, [orderBy]);

  return (
    <EnhancedTableHead
      {...restProps}
      headCells={headers}
      order={order}
      orderBy={orderBy as string}
      onRequestSort={handleRequestSort}
    />
  );
};

DataTableTest.body = function DataTableBody({ children, ...restProps }: any) {
  return (
    <TableBody data-testid='dataTableBody' {...restProps}>
      {children}
    </TableBody>
  );
};

DataTableTest.Row = function DataTableRowCollapse({
  ComponentsView,
  verifyProperty,
  dataCollapse,
  titleCollapse,
  propertyTitle,
  dataFields,
  dataFieldsCollapse,
  setField,
  ...restProps
}: any) {
  const { dataUser, pageStore, rowPerPageStore } = useDataTableStore();

  return (
    <>
      {dataUser?.map((row: any, index: React.Key | null | undefined) => (
        <RowCollapse
          {...restProps}
          key={index}
          row={row}
          dataCollapse={row[dataCollapse]}
          titleProperty={`${titleCollapse} ${row[propertyTitle] || ''}`}
          options={dataFields as any[]}
          optionsCollapse={dataFieldsCollapse}
          verifyProperty={verifyProperty}
          setField={setField}
          ComponentsView={ComponentsView}
          page={pageStore}
          rowsPerPage={rowPerPageStore}
        />
      ))}
    </>
  );
};

DataTableTest.Pagination = function DataTablePagination({
  count,
  rowsPerPage,
  page,
  setPage,
  setRowsPerPage,
  ...restProps
}: any) {
  const { dataUser, loading, setPageStore, setRowPerPageStore } =
    useDataTableStore();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    setPageStore(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setRowPerPageStore(parseInt(event.target.value, 10));
    setPage(0);
    setPageStore(0);
  };

  if (!loading && dataUser) {
    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        {...restProps}
      />
    );
  } else return null;
};

DataTableTest.DensenPadding = function DataTableDensePadding({
  ...restProps
}: any) {
  const { dense, dataUser, setDense, loading } = useDataTableStore();

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  if (!loading && dataUser) {
    return (
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label='Dense padding'
        {...restProps}
      />
    );
  } else return null;
};
