import { ApolloError, useQuery } from '@apollo/client';
import { GET_NEW_ASIN_LOGS } from './queries';
import { NewAsinLogs } from 'interfaces/newAsinLogs';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: NewAsinLogs | undefined;
}

export interface IProductsVars {
  idProduct?: string;
  newAsin?: string;
}

export const useGetLogsNewAsinGraphql = ({
  idProduct,
  newAsin,
}: IProductsVars): IEndpointResponse => {
  const { loading, error, data } = useQuery<NewAsinLogs, IProductsVars>(
    GET_NEW_ASIN_LOGS,
    {
      variables: {
        idProduct,
        newAsin,
      },
    },
  );

  return {
    loading,
    error,
    data,
  };
};
