import { getErrorTransform } from './../../transforms/errors';
import { ApolloError } from '@apollo/client';
import {
  IProductsVars,
  useGetProdutsGraphql,
} from 'api/endPoints/products/get';
import {
  getProductsTransform,
  IServiceResponse,
} from 'api/transforms/products';

interface IServiceState {
  loading: boolean;
  error: ApolloError | string | undefined;
  response: IServiceResponse | undefined;
}

const useGetProducts = (endpointParams: IProductsVars): IServiceState => {
  const { loading, error, data } = useGetProdutsGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: getProductsTransform(data),
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
    };
  }
};

export default useGetProducts;
