import { DataInternal, NewAsinLogs } from '../../interfaces/newAsinLogs';

export interface IServiceResponse {
  data: DataInternal[];
}

export const getNewAsinLogsTransform = (
  data: NewAsinLogs,
): IServiceResponse => {
  const transformData = data.ordersGetAsinHistoryLog.data;

  return { data: transformData };
};
