import { ApolloError, useQuery } from '@apollo/client';
import { GET_VERIFY_COGNITO_TOKEN } from './queries';
import { DataSessionForCognito } from 'interfaces/Cognito';

interface IEndpointResponse {
  loading: boolean;
  error: ApolloError | undefined;
  data: DataSessionForCognito | undefined;
}

export interface ICognitoVars {
  token: string;
  project: string;
}

export const useGetVerifyTokenCognitoGraphql = ({
  token,
  project,
}: ICognitoVars): IEndpointResponse => {
  const { loading, error, data } = useQuery<
    DataSessionForCognito,
    ICognitoVars
  >(GET_VERIFY_COGNITO_TOKEN, {
    variables: { token, project },
  });

  return {
    loading,
    error,
    data,
  };
};
