const FetchApiGet = async (authInfo: any, logout: any) => {
  const response = await fetch(
    `https://4ykwx1vrxc.execute-api.us-west-2.amazonaws.com/dev/users/validatetoken`,
    {
      headers: {
        Authorization: `${authInfo.accessToken}`,
      },
    },
  ).then((fetchResponse) => fetchResponse.json());

  if (!response.result.isValid) {
    logout();
  }

  return response;
};

export default FetchApiGet;
