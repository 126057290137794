import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import useGetOrderDetail from 'api/services/removalOrders/getOrderDetail';
import Alert from '@material-ui/lab/Alert';
import { Typography, Button } from '@material-ui/core';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import ListDetail from './components/ListDetail';
import DataTableDetails from './components/DataTableDetails';

const Detail: React.FC = () => {
  const navigation = useHistory();
  const { id: urlIdOrderDetail } = useParams<any>();
  const { state: paginationState } = useLocation();

  const { error } = useGetOrderDetail({
    _id: urlIdOrderDetail,
  });

  if (error) {
    return (
      <Alert data-testid='alertErrorService' severity='error'>
        {error}
      </Alert>
    );
  }

  const goBack = () => {
    navigation.push('/removal-orders', { paginationState });
  };

  return (
    <div data-testid='detailPage' className='bg-white p-5'>
      <div className='flex justify-between'>
        <Typography variant='h1' component='h2' gutterBottom>
          Order Detail
        </Typography>
        <Button
          data-testid='goBackButton'
          variant='contained'
          color='primary'
          onClick={goBack}>
          <ChevronLeftIcon />
          Go Back
        </Button>
      </div>
      <ListDetail />
      <DataTableDetails />
      <Button variant='contained' color='primary' onClick={goBack}>
        <ChevronLeftIcon />
        Go Back
      </Button>
    </div>
  );
};

export default Detail;
