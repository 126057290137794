import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import create from 'zustand';
import { auth } from './auth';
import { persist } from 'zustand/middleware';

let store = create(
  persist(
    (set, get) => ({
      ...auth(set, get),
    }),
    {
      name: 'localStore',
    },
  ),
);

store = createSelectorHooks(store);

export const useStore = store;
