import { DataSessionForCognito, UserValidateToken } from 'interfaces/Cognito';

export interface IServicesResponse {
  dataSession: UserValidateToken;
}

export const getCognitoVerifyTokenTransform = (
  data: DataSessionForCognito,
): IServicesResponse => {
  const dataSession = {
    status: data.userValidateToken.status,
    userInfo: data.userValidateToken.userInfo,
  };

  return { dataSession };
};
