import tw, { styled } from 'twin.macro';
import ChipMaterial from '@material-ui/core/Chip';
import { status } from 'modules/removalOrders/constants';

export const Chip = styled(ChipMaterial)`
  && {
    ${tw`capitalize`};
    ${(props) =>
      props.label.toLowerCase() === status.pending
        ? `background-color: #ffc107; color: black;`
        : props.label.toLowerCase() === status.shipped
        ? `background-color: #4527a0; color: white;`
        : props.label.toLowerCase() === status['partial received']
        ? `background-color: #00695c; color: white;`
        : props.label.toLowerCase() === status.received
        ? `background-color: #2e7d32; color: white;`
        : props.label.toLowerCase() === status.canceled
        ? `background-color: #d84315; color: white;`
        : props.label.toLowerCase() === status.cancelled
        ? `background-color: #d84315; color: white;`
        : props.label.toLowerCase() === status.done
        ? `background-color: #43a047; color: white;`
        : props.label.toLowerCase() === status['over received']
        ? `background-color: #d84315; color: white;`
        : props.label.toLowerCase() === status.completed
        ? `background-color: #43a047; color: white;`
        : `background-color: #757575; color: white;`}

    .MuiChip-icon {
      ${(props) =>
        props.label.toLowerCase() === status.pending
          ? `color: black;`
          : `color: white;`}
    }
  }
`;
