import React, { useEffect } from 'react';
import InboxIcon from '@material-ui/icons/Inbox';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import TimerIcon from '@material-ui/icons/Timer';

interface StatusBox {
  boxStatus: string;
}

const StatusBox: React.FC<StatusBox> = ({ boxStatus }) => {
  const [setting, setSetting] = React.useState({
    color: '',
    width: '',
  });

  useEffect(() => {
    if (boxStatus === 'empty') {
      setSetting({ color: 'bg-blue-500', width: '' });
    }
    if (boxStatus === 'received') {
      setSetting({ color: 'bg-green-500', width: '' });
    }
    if (boxStatus === 'partial received' || boxStatus === null) {
      setSetting({ color: 'bg-yellow-500', width: 'w-52' });
    }
  }, [boxStatus]);

  return (
    <div>
      <div
        className={`ml-4 m-auto py-3.5 px-6 text-center rounded-full text-white ${setting.color} ${setting.width}`}>
        {boxStatus === 'empty' ? (
          <span>
            <InboxIcon />
            Empty
          </span>
        ) : boxStatus === 'received' ? (
          <span>
            <AssignmentTurnedInIcon />
            Received
          </span>
        ) : boxStatus === 'partial received' || boxStatus === null ? (
          <span>
            <TimerIcon />
            Partial Received
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default StatusBox;
