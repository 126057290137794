import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { dataTableFieldsLogs } from '../constants/index';
import { Button, Typography } from '@material-ui/core';
import useGetLogsNewAsin from 'api/services/products/getNewAsinLogs';
import useDateFormat from 'hooks/useDateFormat';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 40 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 1000,
      height: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    table: {
      minWidth: 650,
      maxHeight: 400,
    },
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
  }),
);

const ModalLogsNewAsin = ({ item }: any) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const { response } = useGetLogsNewAsin({
    idProduct: item.internalId,
    newAsin: item.newAsin,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        type='button'
        variant='contained'
        color='primary'
        onClick={handleOpen}>
        Open Logs
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'>
        {
          <div style={modalStyle} className={classes.paper}>
            <Typography variant='h1' component='h2' gutterBottom>
              Logs
            </Typography>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      {dataTableFieldsLogs.map((dataField: any, index) => (
                        <TableCell align='left' key={index}>
                          {dataField.headerName}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {response?.data.map((row: any) => (
                      <TableRow key={row._id}>
                        {dataTableFieldsLogs.map((field: any, index: any) => {
                          if (field.isDate) {
                            return (
                              <TableCell key={index}>
                                {useDateFormat(row[field.field])}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={index}>
                              {row[field.field]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        }
      </Modal>
    </div>
  );
};

export default ModalLogsNewAsin;
