import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
}));

const Topbar = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color='primary'
      position='fixed'>
      <Toolbar>
        <RouterLink
          to='/dashboard'
          style={{ textAlign: 'center', alignItems: 'center' }}>
          <p
            style={{
              color: '#fff',
              fontSize: '1.2rem',
              marginBottom: '0.5rem',
              fontFamily: 'Roboto, "Helvetica", "Arial", sans-serif',
            }}>
            Returns Operators
          </p>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
