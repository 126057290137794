import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import create from 'zustand';

export const dataTableStore = (set) => ({
  order: 'asc',
  setOrder: (response) =>
    set(() => ({
      order: response,
    })),

  orderBy: 'orderId',
  setOrderBy: (response) =>
    set(() => ({
      OrderBy: response,
    })),

  dense: false,
  setDense: (response) =>
    set(() => ({
      dense: response,
    })),

  dataUser: [],
  setDataUser: (response) =>
    set(() => ({
      dataUser: response,
    })),

  loading: true,
  setLoading: (response) =>
    set(() => ({
      loading: response,
    })),

  pageStore: 0,
  setPageStore: (response) =>
    set(() => ({
      pageStore: response,
    })),

  rowPerPageStore: 10,
  setRowPerPageStore: (response) =>
    set(() => ({
      rowPerPageStore: response,
    })),
});

let store = create((set) => ({
  ...dataTableStore(set),
}));

store = createSelectorHooks(store);

export const useStore = store;
