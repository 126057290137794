import React from 'react';
import DataTableTest from 'components/UI/DataTable/containers/dataTableTest';
import FilterSection from 'components/UI/filters/FilterSection';
import useGetProducts from 'api/services/products/get';
import useOptionsPage from 'hooks/useOptionsPage';
import {
  dataTableFields,
  dataTableRowsPerPage,
  inputsFilters,
} from '../constants';

interface IFilterState {
  asin: string;
  itemName: string;
  customerFnsku: string;
  internalFnsku: string;
  customerSku: string;
  internalSku: string;
  company: string;
}

const ProductsList = () => {
  const { page, setPage, rowsPerPage, setRowsPerPage, validatePage } =
    useOptionsPage();

  const [filterOptions, setFilterOptions] = React.useState<IFilterState>({
    asin: '',
    itemName: '',
    customerFnsku: '',
    internalFnsku: '',
    customerSku: '',
    internalSku: '',
    company: '',
  });

  const [FilterComplete, setFilterComplete] =
    React.useState<IFilterState>(filterOptions);

  const { loading, response } = useGetProducts({
    reqPage: page + 1,
    reqLimit: rowsPerPage,
    filters: FilterComplete,
  });

  React.useEffect(() => {
    setRowsPerPage(dataTableRowsPerPage);
  }, []);

  return (
    <div>
      <>
        <FilterSection
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          inputsFilters={inputsFilters}
          setFilterComplete={setFilterComplete}
          setPage={setPage}
        />
        <div className='mt-10'>
          <DataTableTest data={response?.data as any[]} loading={loading}>
            <DataTableTest.Container>
              <DataTableTest.Headers
                headers={dataTableFields}
                sortBy={'asin'}
              />
              <DataTableTest.body>
                <DataTableTest.Row dataFields={dataTableFields} />
              </DataTableTest.body>
            </DataTableTest.Container>
            <DataTableTest.Pagination
              count={response?.meta.totalDocs || 0}
              page={validatePage(loading, page)}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
            <DataTableTest.DensenPadding />
          </DataTableTest>
        </div>
      </>
    </div>
  );
};

export default ProductsList;
