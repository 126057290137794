import { ApolloError } from '@apollo/client';
import { getErrorTransform } from 'api/transforms/errors';
import {
  IRemovalOrdersVars,
  useGetRemovalOrdersGraphql,
} from 'api/endPoints/removalOrders/get';
import {
  getRemovalOrdersTransform,
  IServiceResponse,
} from 'api/transforms/RemovalOrders';

interface IServiceState {
  loading: boolean;
  error: ApolloError | string | undefined;
  response: IServiceResponse | undefined;
  refetch: () => void;
}

const useGetRemovalOrders = (
  endpointParams: IRemovalOrdersVars,
): IServiceState => {
  const { loading, error, data, refetch } =
    useGetRemovalOrdersGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: getRemovalOrdersTransform(data),
      refetch,
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
      refetch,
    };
  }
};

export default useGetRemovalOrders;
