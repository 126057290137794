const PostFetchLogout = async (authInfo: any, data: any) => {
  console.log(data);

  const response = await fetch(
    `https://4ykwx1vrxc.execute-api.us-west-2.amazonaws.com/dev/logout`,
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `${authInfo.accessToken}`,
        'Content-type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'strict-origin-when-cross-origin',
      body: JSON.stringify(data),
    },
  );

  return response.json();
};

export default PostFetchLogout;
