import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { useHttpClient } from '@base/common/hooks/http-hook';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, TextField, Link, Typography } from '@material-ui/core';
import ErrorModal from '@base/common/UI/ErrorModal';
import LoadingSpinner from '@base/common/UI/LoadingSpinner';
import { AuthContext } from '@base/common/context/auth-context';
import { useConfirmEmail } from '@base/common/hooks/confirmemail-hook';
import { useForm } from '@base/common/hooks/form-hook';
import { useHistory } from 'react-router-dom';
import useStatesGlobalsStore from 'hooks/useStatesGlobalsStore';
import useAuthContext from '@base/common/hooks/auth-context';
import CryptoJS from 'crypto-js';
import cookie from 'react-cookies';
import useGetCognitoVerifyToken from 'api/services/cognito/get';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    backgroundColor: '#f4f6f8',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage:
      'url(https://cdn.pixabay.com/photo/2016/06/27/11/20/warehouse-1482301_1280.jpg)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.primary,
    fontWeight: 300,
    marginTop: theme.spacing(90),
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 75,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

const SignIn = (props) => {
  const navigation = useHistory();
  const auth = useContext(AuthContext);
  const { saveInternaluserId } = useAuthContext();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { hasError, handleChange, formState } = useForm(schema);

  const { confirmEmail, clearConfirmEmail } = useConfirmEmail();

  const [tokenCognito, setTokenCognito] = useState('');

  const { setAuthInfo } = useStatesGlobalsStore();

  const {
    loading,
    response,
    error: errorValidateToken,
  } = useGetCognitoVerifyToken({
    token: tokenCognito,
    project: 'operators',
  });

  const tokenAndModule = window.location.search.split('token=')[1];
  useEffect(() => {
    if (tokenAndModule) {
      token = tokenAndModule.split('module=')[0].replace('&', '');
    }
    if (token) {
      setTokenCognito(token);
    } else {
      window.location.href =
        'https://returns-53785.bubbleapps.io/operators_login';
    }
  }, [tokenAndModule]);

  useEffect(() => {
    if (response?.dataSession.status) {
      moduleApp = tokenAndModule.split('module=')[1];
      setAuthInfo({
        userId: response.dataSession.userInfo[0]._id,
        userInternalId: response.dataSession.userInfo[0].userInternalId,
        accountOwner: response.dataSession.userInfo[0].accountOwner,
        email: response.dataSession.userInfo[0].email,
        tenant: response.dataSession.userInfo[0].companyName,
        token: response.dataSession.userInfo[0].accessToken,
        status: { status: response.dataSession.userInfo[0].status },
        linkedAccounts: response.dataSession.userInfo[0].linkedAccounts,
        accessToken: tokenCognito,
        username: response.dataSession.userInfo[0].username,
      });

      auth.login(
        response.dataSession.userInfo[0]._id,
        response.dataSession.userInfo[0].email,
        response.dataSession.userInfo[0].companyName,
        response.dataSession.userInfo[0].accessToken,
        null,
        true,
        response.dataSession.userInfo[0].status,
      );

      navigation.push(`/${moduleApp}`);
    }

    if (errorValidateToken) {
      window.location.href =
        'https://returns-53785.bubbleapps.io/operators_login';
    }
  }, [tokenCognito, loading]);

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/api/users/login',
        'POST',
        JSON.stringify({
          email: formState.values.email,
          password: formState.values.password,
          project: 'operators',
        }),
        {
          'Content-Type': 'application/json',
        },
      );

      if (responseData.token) {
        setAuthInfo(responseData);
        auth.login(
          responseData.userId,
          responseData.email,
          responseData.tenant,
          responseData.token,
          null,
          true,
          responseData.status,
        );
        navigation.push('/dashboard');
      }
    } catch (err) {}
  };

  let token = '';
  let moduleApp = '';

  // const tokenAndModule = window.location.search.split('hash=')[1];

  // useEffect(() => {
  //   if (tokenAndModule) {
  //     token = tokenAndModule.split('module=')[0].replace('&', '');
  //     moduleApp = tokenAndModule.split('module=')[1];
  //   }
  //   if (token) {
  //     cookie.save('hash', token);

  //     // let bytes = CryptoJS.AES.decrypt(token, 'Returns@2023');

  //     // const dataResponse = bytes.toString(CryptoJS.enc.Utf8);

  //     // const decryptedData = JSON.parse(dataResponse);

  //     const sanitized = decodeURIComponent(token);
  //     const ciphertext = sanitized.replace(/ /g, '+'); // Restaurar espacios en el ciphertext
  //     const decryptedBytes = CryptoJS.AES.decrypt(ciphertext, 'Returns@2023');
  //     const decrypted = decryptedBytes.toString(CryptoJS.enc.Utf8);

  //     const decryptedData = JSON.parse(decrypted);

  //     setAuthInfo({ ...decryptedData, tokenTwo: token });
  //     auth.login(
  //       decryptedData.userId,
  //       decryptedData.email,
  //       decryptedData.tenant,
  //       decryptedData.token,
  //       null,
  //       true,
  //       decryptedData.status,
  //     );
  //     navigation.push(`/${moduleApp}`);
  //     return;
  //   }

  //   // window.location.href =
  //   //   'https://returns-53785.bubbleapps.io/operators_login';
  // }, []);

  return (
    <div className={classes.root}>
      {confirmEmail && (
        <>
          {emailConfirmed === true && (
            <ErrorModal
              open={!!confirmEmail}
              alert={
                <div className='flex flex-col items-center'>
                  <CheckCircleIcon
                    fontSize='large'
                    className='text-green-500'
                  />
                  <h3 className='text-xl font-semibold mb-5'>
                    Congratulations
                  </h3>
                  <p className='text-base'>
                    Your account has been activated successfully
                  </p>
                  <p className='text-base'>
                    You can now sign in to your account
                  </p>
                </div>
              }
              buttonClose={true}
              clearError={clearConfirmEmail}
            />
          )}

          {emailConfirmed === false && (
            <ErrorModal
              open={!!confirmEmail}
              title='Email Confirmation'
              alert={confirmEmail}
              clearError={clearConfirmEmail}
              buttonClose={true}
            />
          )}
        </>
      )}
      {error && (
        <ErrorModal
          open={!!error}
          title='Error Signing Up'
          alert={error}
          clearError={clearError}
          buttonClose={true}
        />
      )}
      {auth.error && (
        <ErrorModal
          open={!!auth.error}
          title='Error Signing Up'
          alert={auth.error}
          clearError={auth.clearError}
          buttonClose={true}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant='h1'></Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant='body1'></Typography>
                <Typography
                  className={classes.bio}
                  variant='body2'></Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}></div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant='h2'>
                  Sign in
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label='Email address'
                  name='email'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.email || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label='Password'
                  name='password'
                  onChange={handleChange}
                  type='password'
                  value={formState.values.password || ''}
                  variant='outlined'
                />
                <Button
                  className={classes.signInButton}
                  color='primary'
                  disabled={!formState.isValid}
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'>
                  Sign in now
                </Button>
                {/* <Typography color='textSecondary' variant='body1'>
                  Don't have an account?{' '}
                  <Link component={RouterLink} to='/sign-up' variant='h6'>
                    Sign up
                  </Link>
                </Typography> */}
                <Typography color='textSecondary' variant='body1'>
                  {' '}
                  Forgot your password?{' '}
                  <Link
                    component={RouterLink}
                    to='/ForgotPassword'
                    variant='h6'>
                    Reset Password
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(SignIn);
