import { ApolloError } from '@apollo/client';
import { getErrorTransform } from 'api/transforms/errors';
import {
  useGetVerifyTokenCognitoGraphql,
  ICognitoVars,
} from '../../endPoints/cognito/get';
import {
  getCognitoVerifyTokenTransform,
  IServicesResponse,
} from 'api/transforms/cognito/verifyToken';

interface IServiceState {
  loading: boolean;
  error: ApolloError | string | undefined;
  response: IServicesResponse | undefined;
}

const useGetCognitoVerifyToken = (
  endpointParams: ICognitoVars,
): IServiceState => {
  const { loading, error, data } =
    useGetVerifyTokenCognitoGraphql(endpointParams);

  if (data) {
    return {
      loading,
      error,
      response: getCognitoVerifyTokenTransform(data),
    };
  } else {
    return {
      loading,
      error: getErrorTransform(error),
      response: data,
    };
  }
};

export default useGetCognitoVerifyToken;
