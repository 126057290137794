import React from 'react';
import RoutesBase, {
  ControlledRoute,
  PrivateRoute,
} from '@base/common/RoutesBase';
import { Switch } from 'react-router-dom';
import Detail from 'modules/removalOrders/containers/Detail';
import Products from './pages/products';

const RemovalOrders = React.lazy(() => import('pages/RemovalOrders'));
const Customers = React.lazy(() => import('pages/Customers'));
const ReceiveBox = React.lazy(() => import('pages/receive-box'));
const Sales = React.lazy(() => import('pages/Sales'));
const Settings = React.lazy(() => import('pages/Settings'));
const ActiveListings = React.lazy(() => import('pages/ActiveListings'));
const NoMatchPage = React.lazy(() => import('pages/noMatchPage'));

const Routes: React.FC = () => {
  return (
    <RoutesBase>
      <Switch>
        <PrivateRoute exact path='/receive-box'>
          <ReceiveBox />
        </PrivateRoute>
        <PrivateRoute exact path='/active-listings'>
          <ActiveListings />
        </PrivateRoute>

        <ControlledRoute exact path='/removal-orders'>
          <RemovalOrders />
        </ControlledRoute>
        <ControlledRoute exact path='/removal-orders/:id'>
          <Detail />
        </ControlledRoute>
        <ControlledRoute exact path='/customers'>
          <Customers />
        </ControlledRoute>
        <ControlledRoute exact path='/sales'>
          <Sales />
        </ControlledRoute>
        <ControlledRoute exact path='/products'>
          <Products />
        </ControlledRoute>
        <ControlledRoute exact path='/settings'>
          <Settings />
        </ControlledRoute>

        <ControlledRoute exact path='*'>
          <NoMatchPage />
        </ControlledRoute>
      </Switch>
    </RoutesBase>
  );
};

export default Routes;
